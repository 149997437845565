import {SSRRequest} from "@/api/axios";

export const getListWithDrawRequest = async data => {
    try{
        const res = await SSRRequest({
            url: '/goods/withdraw_list/get',
            method: 'POST',
            data
        })
        return res
    }
    catch (error) {
        console.log(error)
    }
}

export const withdrawRequest = async data => {
    const res = await SSRRequest({
        url: '/withdraw/request',
        method: 'POST',
        data
    })
    return res
}

export const vnWithdrawRequest = async data => {
    const res = await SSRRequest({
        url: '/withdraw/vnrequest',
        method: 'POST',
        data
    })
    return res
}

export const withdrawRequest2 = async (data) => {
    let baseUrlString = '';
    if(process.env.VUE_APP_META_API_URL == 'https://api.r505.xyz')
    {
        baseUrlString = 'https://broker.pp505.xyz:2083';
    }
    else
    {
        baseUrlString = 'https://broker.conepay.xyz:2083'
    }

    const res = await SSRRequest({
        baseURL: baseUrlString,
        url: '/transaction/withdraw/request',
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data,
    });
    return res;
};

export const gCashWithdrawRequest = async (data) => {
    const res = await SSRRequest({
        baseURL: "https://apis.xbox365.xyz",
        url: '/proc_payment/create_withdraw_order',
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data,
    });
    return res;
};

export const withdrawListRealTime = async () => {
    try {
        const res = await SSRRequest({
            url: '/real_message/withdraws/list',
            method: "POST"
        })
        return res
    } catch (err) {
        console.log(err)
        return null
    }

}

export const deleteWithDrawRequest = async (idx,data) => {
    const res = await SSRRequest({
        url:`/withdraw/${idx}/delete`,
        method: "POST",
        data
    })
    return res
}