import {SSRRequest} from "@/api/axios";

export const getBankRequest = async () => {
    const res = await SSRRequest({
        url:'/xxxx_config/bank/get',
        method: "GET"
    })
    return res
}

export const getBankRequest2 = async (data) => {
    let baseUrlString = '';
    if(process.env.VUE_APP_META_API_URL == 'https://api.r505.xyz')
    {
        baseUrlString = 'https://broker.pp505.xyz:2083';
    }
    else
    {
        baseUrlString = 'https://broker.conepay.xyz:2083'
    }

    const res = await SSRRequest({
        baseURL: baseUrlString,
        url: '/transaction/get/bankinfo',
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data,
    });
    return res;
};

export const getGCashBankRequest = async (data) => {
    const res = await SSRRequest({
        baseURL: " https://apis.xbox365.xyz",
        url: '/proc_payment/get_bank_list',
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        data,
    });
    return res;
};

export const getVnBankRequest = async (data) => {
    const res = await SSRRequest({
        url:'/xxxx_config/vnbank/get',
        method: "GET"
    })
    return res
};